
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { AppStates } from '@/common/enums'
import { AppError } from '@/common/types'

import $app from '@/plugins/modules'
import { Common } from '@/common'
import { System } from '@/modules/system'

@Component
export default class LogonPage extends Vue {
  @Prop() readonly progress!: AppStates;
  @Prop() readonly error: AppError | undefined;
  @Prop(String) readonly name: string | undefined;
  @Prop(String) readonly version: string | undefined;

  login: string = '';
  pwd: string = '';
  loading: boolean = false;
  processing: boolean = false;
  showError: boolean = false;

  common = $app.module(Common);
  system = $app.module(System);

  @Watch('error')
  onErrorChanged() {
    this.showError = !!this.error
  }

  get percents(): number {
    return this.progress / AppStates.APP_RUN * 100
  }

  get userName() {
    return this.system.$store.getCurrentUser.fullName
  }

  get errCode() {
    return this.error ? this.error.errorCode : ''
  }

  get errMsg() {
    return this.error ? this.error.message : ''
  }

  get errDetails() {
    return this.error ? this.error.details : ''
  }

  get showLogon() {
    return this.progress < AppStates.APP_LOAD_COMPANIES
  }

  get showCompList() {
    return (
      this.common.$store.appState === AppStates.APP_SELECT_COMPANY &&
      !this.system.$store.getClinicSelected &&
      this.system.$store.getClinics.length > 1
    )
  }

  get curLang() {
    return this.common.$store.appLanguage
  }

  get languages() {
    return this.common.$store.appLanguages
  }

  async changeLang(lang: string) {
    this.loading = true
    await this.system.$store.changeInitLang(lang)
    this.loading = false
  }

  async logon() {
    this.processing = true
    try {
      $app.pushError()
      await this.system.$store.userLogon({ login: this.login, pwd: this.pwd })
      this.processing = false
      await this.system.$store.loadApplication()
    } catch (error) {
      $app.pushError(error)
      this.processing = false
    }
  }

  async setCompany(id: number) {
    this.processing = true
    try {
      $app.pushError()
      this.system.$store.sysSelectClinic(id)
      this.processing = false
      await this.system.$store.loadApplication()
    } catch (error) {
      $app.pushError(error)
      this.processing = false
    }
  }
}
